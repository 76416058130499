<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  mounted () {
    var r = document.querySelector(':root');
    if (process.env.VUE_APP_FONT_URL && process.env.VUE_APP_FONT_NAME) {
      var link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', process.env.VUE_APP_FONT_URL);
      document.head.appendChild(link);
      r.style.setProperty('--primary-font-family', process.env.VUE_APP_FONT_NAME)
    }
  }
}
</script>
